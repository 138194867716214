<template>
  <b-card no-body class="p-2">
    <h3 class="mb-2">
      {{
        profileHability.perfilHabilidadId == 0
          ? $t("Routes.ProfileHabilityAdd")
          : $t("Routes.ProfileHabilityEdit")
      }}
    </h3>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.profileHability.columns.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.profileHability.columns.name')"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="usuario"
                  v-model="profileHability.perfilId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nombre"
                  :options="profiles"
                  :reduce="(option) => option.perfilId"
                  @search="searchProfiles"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.profileHability.columns.hability')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.profileHability.columns.hability')"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="usuario"
                  v-model="profileHability.habilidadId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="texto"
                  :options="habilities"
                  :reduce="(option) => option.id"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.profileHability.columns.years')"
            >
              <b-form-group
                :label="$t('humanResources.profileHability.columns.years')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input
                  v-model="profileHability.anios"
                  type="number"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.profileHability.columns.months')"
            >
              <b-form-group
                :label="$t('humanResources.profileHability.columns.months')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input
                  v-model="profileHability.meses"
                  type="number"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.profileHability.columns.percentage')"
            >
              <b-form-group
                :label="$t('humanResources.profileHability.columns.percentage')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input
                  v-model="profileHability.porcentaje"
                  type="number"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="float-right mt-2 mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner v-if="addingProfileHability" small class="mr-1" />
            {{ profileHability.perfilHabilidadId == 0 ? $t("Lists.Add") : $t("Lists.Edit") }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BSpinner,
  BFormInvalidFeedback,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import humanResources from '@/services/humanresources.service'
import optionService from '@/services/option.service'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import router from '@/router'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,

    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  setup() {
    const profiles = ref([])
    const habilities = ref([])
    const addingProfileHability = ref(false)
    const profileHability = ref({
      perfilHabilidadId: 0,
      perfilId: null,
      habilidadId: null,
      anios: null,
      meses: null,
      porcentaje: null,
    })

    const blankProfileHability = {
      perfilHabilidadId: 0,
      perfilId: null,
      habilidadId: null,
      anios: null,
      meses: null,
      porcentaje: null,
    }

    const {
      fetchProfileHability,
      createProfileHability,
      updateProfileHability,
      fetchProfiles,
    } = humanResources()

    const {
      fetchOptions,
    } = optionService()

    const resetProfileHability = () => {
      profileHability.value = JSON.parse(JSON.stringify(blankProfileHability))
    }

    if (router.currentRoute.params.perfilHabilidadId) {
      fetchProfileHability(router.currentRoute.params.perfilHabilidadId, data => {
        profileHability.value = data
      })
    } else {
      profileHability.value = JSON.parse(JSON.stringify(blankProfileHability))
    }
    const searchProfiles = text => {
      fetchProfiles({ searchQuery: text, limit: 50 }, data => {
        profiles.value = data.perfiles
      })
    }
    fetchProfiles({ limit: 50 }, data => {
      profiles.value = data.perfiles
    })
    fetchOptions({ nombreDominio: 'RecursosHumanosHabilidad' }, data => {
      habilities.value = data
    })
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetProfileHability)

    const onSubmit = () => {
      addingProfileHability.value = true
      if (profileHability.value.perfilHabilidadId === 0) {
        createProfileHability(profileHability.value, () => {
          addingProfileHability.value = false
          router.push({ name: 'apps-human-resources-profile-hability-list' })
        })
          .catch(() => {
            addingProfileHability.value = false
          })
      } else {
        updateProfileHability(profileHability.value.perfilHabilidadId, profileHability.value, () => {
          addingProfileHability.value = false
          router.push({ name: 'apps-human-resources-profile-hability-list' })
        })
      }
    }

    return {
      profiles,
      habilities,
      profileHability,
      addingProfileHability,
      required,
      getValidationState,
      refFormObserver,
      resetForm,
      onSubmit,
      searchProfiles,
    }
  },
}
</script>
